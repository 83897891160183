

































































































import i18n from '@/lang';
import { Component, Vue, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import SaveBtnGroup from '@/components/saveBtnGroup.vue';
import { ReqFilterTable } from '@/interface';
import { urlDecode } from '@/utils';
import {
  getAllGroupPepoleListApi,
  getGroupPepoleListApi,
  addUserToGroupApi
} from '@/api/user_group_api'
import { throttle } from '@/utils/delayed';
interface UserListItem {
  id: number,
  user: string
}
@Component({
  name: 'UserSetting',
  components: {
    SaveBtnGroup
  }
})
export default class UserSetting extends Vue{
  @PropSync('visible') _visible!: boolean;
  @Prop() groupId!: number|string

  @Watch('_visible')
  changeShow(){
    if(this._visible){
      this.getAllGroupPepoleList();
      this.getGroupPepoleList();
    }else{
      this.clear();
    }
  }
  searchAllgroup:any=throttle(this.getAllGroupPepoleList, 500);
  allPepoleFilter: ReqFilterTable<any> = {
    currentPage: 1,
    showCount: 100,
    totalResult:0,
    data: {
      fkGroupId:'',
      keyword:'',
    },
  };
  // 选中的用户列表
  checkedAll: string[] = [];
  // 全部用户列表
  allPepole: any[] = [];
  // 当前用户检索条件
  currentPepoleFilter:any = {
      data: '',
  };
  // 当前用户 已勾选的数组
  checkedCurrent: string[] = [];
  // 当前用户列表
  currentPepole: any[] = [];
  // 当前用户列表 查询列表
  currentSearchPepole:any=[];

  allAddUser: UserListItem[] = [];
  allDelUser: UserListItem[] = [];

  // 翻页
  handleCurrentChange(page:any){
    this.allPepoleFilter.currentPage = page;
    this.getAllGroupPepoleList();
  }
  // 页数
  handleSizeChange(size: number){
    this.allPepoleFilter.showCount = size;
    this.allPepoleFilter.currentPage = 1;
    this.getAllGroupPepoleList();
  }
  // 获取全部用户列表
  getAllGroupPepoleList(){
    this.allPepoleFilter.data.fkGroupId=this.groupId;
    this.allPepoleFilter.currentPage = 1;
    getAllGroupPepoleListApi(this.allPepoleFilter).then(res=>{
        if(res){
          this.allPepole=this.getListType(res.data.datas);
          Object.assign(this.allPepoleFilter,res.data.page)
        }
    })
  }
  // 获取当前组别用户列表
  getGroupPepoleList(){
    getGroupPepoleListApi(this.groupId).then(res=>{
        if(res){
          this.currentPepole=this.getListType(res.data.data)
          this.currentSearchPepole=this.currentPepole
        }
    })
  }
  // 获取列表的格式
  getListType(list:any){
    let data:any=[];
    if(list&&list.length>0){
      data=list.map((item:any)=>{
        return {
          id:item.id,
          name:item.name,
          nameEn:item.nameEn,
          position:item.position,
          company:item.company,
          nickname: item.nickname
        }
      })
    }
    return data;
  }
  addUserToGroup(){
    if(this.checkedAll.length){
      const filterAllPepole = this.allPepole.filter(item => {
        return this.checkedAll.indexOf(`id=${item.id}&name=${item.name}&nameEn=${item.nameEn}&position=${item.position}&company=${item.company}&nickname=${item.nickname}`) < 0;
      });
      this.allPepole = filterAllPepole;
      this.checkedAll.forEach(item => {
        const newUser = urlDecode(item) as UserListItem;
        const isNewDel = this.allDelUser.findIndex(addItem=> addItem.id === newUser.id)
        isNewDel >= 0 ? this.allDelUser.splice(isNewDel, 1): this.allAddUser.push(newUser);
        this.currentSearchPepole.push(newUser);
      });
    }
    this.checkedAll = [];
  }
  // 当前用户检索
  selectSearch(){
    this.currentSearchPepole=[];
    if(this.currentPepoleFilter.data&&this.currentPepoleFilter.data!==''){
      this.currentPepole.forEach((item:any)=>{
        if(item.name.indexOf(this.currentPepoleFilter.data)!==-1){
          this.currentSearchPepole.push(item);
        }
      })
    }else{
      this.currentSearchPepole=this.currentPepole
    }
  }
  addUserToAll(){
    if(this.checkedCurrent.length){
      const filterAllPepole = this.currentSearchPepole.filter((item:any) => {
        return this.checkedCurrent.indexOf(`id=${item.id}&name=${item.name}&nameEn=${item.nameEn}&position=${item.position}&company=${item.company}&nickname=${item.nickname}`) < 0;
      });
      this.currentSearchPepole = filterAllPepole;
      this.checkedCurrent.forEach(item => {
        const newUser = urlDecode(item) as UserListItem;
        const isNewAdd = this.allAddUser.findIndex(addItem=> addItem.id === newUser.id)
        isNewAdd >= 0 ? this.allAddUser.splice(isNewAdd, 1): this.allDelUser.push(newUser);
        this.allPepole.push(newUser)
      })
    }
    this.checkedCurrent = [];
  }
  cancelForm(){
    this._visible = false;
  }
  submitForm(){
      let list:any=[];
      if(this.currentSearchPepole.length>0){
          this.currentSearchPepole.forEach((item:any)=>{
            list.push({
              fkUserGroupId:this.groupId,
              fkUserId:item.id
            })
        })
      }else{
        list.push({
          fkUserGroupId:this.groupId,
        })
      }
      
      addUserToGroupApi(list).then(res=>{
        if(res){
          this.$message.success(i18n.t('addSuccess') as string);
          this.clear()
          this.cancelForm();
          this.$emit('Success');
        }
      })
  }
  // 清空
  clear(){
    this.checkedCurrent=[];
    this.checkedAll=[];
    this.allPepole=[];
    this.currentPepole=[];
    this.currentSearchPepole=[];
    this.currentPepoleFilter.data='';
    this.allPepoleFilter.data.fkGroupId='';
    this.allPepoleFilter.data.keyword='';
    this.allPepoleFilter.currentPage=1;
    this.allPepoleFilter.showCount=20;
    this.allPepoleFilter.totalResult=0;
  }
}
