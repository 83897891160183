

































import i18n from '@/lang';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AddGroup } from '@/interface/system';
import { addGroupApi, editGroupApi } from '@/api/user_group_api';
import saveBtnGroup from '@/components/saveBtnGroup.vue';
@Component({
  name: 'editInstitutionGroup',
  components: { saveBtnGroup }
})
export default class EditInstitutionGroup extends Vue {
  @Prop({ default: '' }) row?: any;
  @Prop({ default: '' }) type!: string;
  isShow: boolean = false;
  loading: boolean = false;
  AddGroup: AddGroup = {
    groupKey: '',
    groupName: '',
    viewOrder: '',
  }
  formRules: any = {
    groupName: [
      {
        required: true,
        message: i18n.t('pleaseInput'),
        trigger: 'blur',
      }
    ],
    groupKey: [
      {
        required: true,
        message: i18n.t('pleaseInput'),
        trigger: 'blur',
      }
    ],
    viewOrder: [
      {
        required: true,
        message: i18n.t('pleaseInput'),
        trigger: 'blur',
      }
    ],
  }
  @Watch('isShow')
  private onShowChange(value: boolean) {
    if (value) {
      if (this.type === 'edit') {
        this.AddGroup = Object.assign(this.AddGroup, this.row)
      } else {
        this.clear();
      }
    } else {
      this.reset();
    }
  }

  // // 保存
  handleResource() {
    const Form: any = this.$refs.editForm;
    Form.validate((valid: any) => {
      if (valid === true) {
        if (this.type === 'add') {
          this.add();
        } else if (this.type === 'edit') {
          this.edit();
        }
      }
    })
  }
  // 新增
  add() {
    addGroupApi(this.AddGroup).then(res => {
      this.$message.success(i18n.t('addSuccess') as string);
      this.$emit('Success');
      this.isShow = false;
      (this.$refs.saveBtnGroup as any).loadingFalse();
    }).catch(err => {
      (this.$refs.saveBtnGroup as any).loadingFalse();
      this.$message.error(i18n.t('addFail') as string);
    })
  }
  // 编辑
  edit() {
    editGroupApi(this.AddGroup).then(res => {
      this.$message.success(i18n.t('editSuccess') as string);
      this.$emit('Success');
      this.isShow = false;
      (this.$refs.saveBtnGroup as any).loadingFalse();
    }).catch(err => {
      (this.$refs.saveBtnGroup as any).loadingFalse();
      this.$message.error(i18n.t('editFail') as string);
    })
  }
  // 清空
  clear() {
    this.AddGroup.groupKey = '';
    this.AddGroup.groupName = '';
    this.AddGroup.viewOrder = '';
    this.AddGroup.id = '';
  }
  // 重置
  reset() {
    this.clear();
    const el: any = this.$refs.editForm;
    el.resetFields();
  }
}
