





































import i18n from '@/lang';
import { Component, Vue, Prop, Watch, PropSync, Ref } from 'vue-property-decorator';
import SaveBtnGroup from '@/components/saveBtnGroup.vue';
import { LayoutStoreModule } from '@/store/LayoutModule';
import { CusRouteConfig } from '@/router';
import { Tree } from 'element-ui';
import {getResourceByGroupApi,addResourceToGroupApi} from '@/api/user_group_api'
import { getResourseAllTree } from '@/api/system_api';
import { ResourceTree } from '@/interface/system';
import { UserStoreModule } from '@/store/UserModule';
@Component({
  name: 'SystemResource',
  components: {
    SaveBtnGroup
  }
})
export default class SystemResource extends Vue{
  @PropSync('visible') _visible!: boolean;
  @Prop({type: String}) id!: string;
  @Ref('myTree') myTree!: Tree;
  // 回显
  defaultExpandedKeys: string[] = [];
 
  keyword = '';
  resourceData: ResourceTree[] = [];
  // 保存的数组
  saveList:any=[];
  resourceProp = {
    children: 'resourceDtoList',
    label: 'resourceName',
  }
  @Watch('keyword')
  filterText(val: string) {
    this.myTree.filter(val);
  }
  @Watch('_visible')
  filterVisible(){
    if(this._visible){
      this.getResourceByGroup();
      this.keyword='';
    }else{
      this.defaultExpandedKeys=[];
      this.resourceData=[];
    }
  }
  // 筛选
  filterResource(value: string, data: ResourceTree){
    if(!value) return true;
    return data.resourceName.indexOf(value) >= 0;
  }
  // 回显资源
  async getResourceByGroup(){
    try {
      const treeRet = await getResourseAllTree()
      if(treeRet.data.code == 1003 && treeRet.data.data ){
        if(UserStoreModule.isSupperPermission){
          this.resourceData = treeRet.data.data;
        } else {
          this.resourceData = this.filterTreeByMyPermission(treeRet.data.data);
        }
      }
      const groupRet = await getResourceByGroupApi(this.id);
      if(groupRet.data.code == 1003 && groupRet.data.data) {
        this.defaultExpandedKeys = groupRet.data.data;
      }
    } catch {
      this.defaultExpandedKeys = [];
    }
    
  }
  // 过滤可以给予的权限
  filterTreeByMyPermission(resource: ResourceTree[]){
    const myPermission = UserStoreModule.myPermission;
    const filterTree: ResourceTree[] = [];
    function filter(tree: ResourceTree[] , newTree: ResourceTree[] = []){
      for (let i = 0, lng = tree.length; lng > i ; i++ ){
        if (myPermission.indexOf(tree[i].resourceKey) >= 0){
          let j = newTree.push({
            resourceDtoList: [],
            resourceKey: tree[i].resourceKey,
            resourceName: tree[i].resourceName,
          }) - 1 ;
          filter(tree[i].resourceDtoList, newTree[j].resourceDtoList)
        } 
      }
    }
    filter(resource, filterTree);
    return filterTree;
  }
  cancelForm(){
    this._visible = false;
  }
  submitForm(){
    const list:any=[];
    const resourcelist = this.myTree.getCheckedNodes() as ResourceTree[]
    if(resourcelist.length>0){
      resourcelist.forEach( item => {
        list.push({
          fkUserGroupId: this.id,
          fkResourceKey: item.resourceKey
        })
      })
    } else {
      list.push({
        fkUserGroupId:this.id,
      })
    }
    addResourceToGroupApi(list).then(res=>{
      if(res){
        this.$message.success(i18n.t('updateSuccess') as string);
        this._visible = false;
        this.$emit('Success');
      }
    })
  }
}
