var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base_container_main_scroll"},[_c('head-menu-box-with-country',{attrs:{"create-show":_vm.isPermission('systemConfigUserGroupAdd'),"keyword":_vm.reqFilterTable.data.groupName,"isNeedCountry":false},on:{"search":_vm.search,"reset":_vm.reset,"refresh":_vm.refresh,"create":_vm.create,"update:keyword":function($event){return _vm.$set(_vm.reqFilterTable.data, "groupName", $event)}}}),_c('table-list',{attrs:{"listData":_vm.list,"headData":[
      { prop: 'groupName', label: _vm.$t('userGroup'), width: 180 },
      {
        prop: 'resourceTotle',
        label: _vm.$t('resourceNum'),
        width: 130,
        slot: 'resourceTotle',
        show: true,
      },
      {
        prop: 'userTotle',
        label: _vm.$t('personNum'),
        width: 120,
        slot: 'userTotle',
        show: true,
      },
      { prop: 'gmtCreateUser', label: _vm.$t('createPerson'), width: 140 },
      { prop: 'gmtCreate', label: _vm.$t('createTime'), width: 180 },
      { prop: 'gmtModifiedUser', label: _vm.$t('changePerson'), width: 140 },
      { prop: 'gmtModified', label: _vm.$t('changeTime'), width: 180 },
      {
        prop: '',
        label: _vm.$t('opera'),
        width: 140,
        slot: 'opera',
        show: true,
        fixed: 'right',
      } ],"reqFilterTable":_vm.reqFilterTable},on:{"handleSizeChange":_vm.handleSizeChange,"handleCurrentChange":_vm.handleCurrentChange},scopedSlots:_vm._u([{key:"resourceTotle",fn:function(ref){
    var row = ref.row;
return [(_vm.isPermission('systemConfigUserGroupEdit'))?_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.showResource(row.id)}}},[_vm._v(_vm._s(row.resourceTotle))]):_c('span',[_vm._v(_vm._s(row.resourceTotle))])]}},{key:"userTotle",fn:function(ref){
    var row = ref.row;
return [(_vm.isPermission('systemConfigUserGroupEdit'))?_c('span',{staticClass:"pointer",on:{"click":function($event){return _vm.showGroup(row.id)}}},[_vm._v(_vm._s(row.userTotle))]):_c('span',[_vm._v(_vm._s(row.userTotle))])]}},{key:"opera",fn:function(scope){return [_c('icon-button',{attrs:{"showMoveUp":_vm.isPermission('systemConfigUserGroupEdit'),"showMoveDown":_vm.isPermission('systemConfigUserGroupEdit'),"showEdit":_vm.isPermission('systemConfigUserGroupEdit'),"showRemove":_vm.isPermission('systemConfigUserGroupDelete')},on:{"handleMoveUp":function($event){return _vm.moveUp(scope.index)},"handleMoveDown":function($event){return _vm.moveDown(scope.index)},"handleEdit":function($event){return _vm.editId(scope.row)},"handleRemove":function($event){return _vm.deleteId(scope.row)}}})]}}])}),_c('system-resource',{attrs:{"visible":_vm.resource.visible,"id":_vm.resource.id},on:{"update:visible":function($event){return _vm.$set(_vm.resource, "visible", $event)},"Success":_vm.getList}}),_c('user-setting',{attrs:{"visible":_vm.userSetting.visible,"group-id":_vm.userSetting.id},on:{"update:visible":function($event){return _vm.$set(_vm.userSetting, "visible", $event)},"Success":_vm.getList}}),_c('add-group',{ref:"addGroup",attrs:{"type":_vm.changeType,"row":_vm.rowObj},on:{"Success":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }