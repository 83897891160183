


























































































import i18n from '@/lang';
import { Vue, Component } from 'vue-property-decorator';
import PaginationBox from '@/view/admin/components/pagination-box.vue'
import HeadMenuBoxWithCountry from '@/view/admin/components/head-menu-box-with-country.vue'
import SystemResource from './component/system_resource.vue'
import UserSetting from './component/user_setting.vue'
import addGroup from './component/add_group.vue';
import { ReqFilterTable } from '@/interface';
import {
  getUserGroupListApi,
  userGroupDeleteApi,
  userGroupMoverOrder
} from '@/api/user_group_api'
import { debounce } from '@/utils/delayed';
import tableList from '@/components/tableList.vue';
@Component({
  name: 'UserGroup',
  components: {
    PaginationBox,
    HeadMenuBoxWithCountry,
    SystemResource,
    UserSetting,
    addGroup,
    tableList
  }
})
export default class UserGroup extends Vue {
  debounceInit = debounce(this.getList, 500, true);
  reqFilterTable: ReqFilterTable<{ groupName: string }> = {
    currentPage: 1,
    showCount: 10,
    totalResult: 0,
    data: {
      groupName: ''
    }
  };
  // 操作类型
  private changeType: string = 'add';
  // 编辑对象
  private rowObj: any = '';
  private list = []
  resource = {
    visible: false,
    id: ''
  }
  userSetting = {
    visible: false,
    id: '',
  }
  myPermission: string[] = [];
  get isPermission(){
    return (str: string) => {
      return this.myPermission.indexOf(str) >= 0;
    }
  }
  created() {
    this.myPermission = this.$route.meta.permission
    this.debounceInit();
  }
  // 获取列表
  getList() {
    getUserGroupListApi(this.reqFilterTable).then(res => {
      if (res) {
        this.list = res.data.datas;
        Object.assign(this.reqFilterTable, res.data.page);
      } else {
        this.list = [];
      }
    }).catch(() => {
      this.list = [];
    })
  }
  search() {
    this.reqFilterTable.currentPage = 1;
    this.getList();
  }
  reset() {
    this.reqFilterTable.currentPage = 1;
    this.reqFilterTable.showCount = 10;
    this.reqFilterTable.data.groupName = '';
    this.getList();
  }
  refresh() {
    this.getList();
  }
  create() {
    this.changeType = 'add';
    const el: any = this.$refs.addGroup;
    el.isShow = true;
  }
  moveUp(index: number) {
    if (index !== 0) {
      this.moveCity([this.list[index], this.list[index - 1]]);
    }
  }
  moveDown(index: number) {
    if (index !== this.list.length - 1) {
      this.moveCity([this.list[index], this.list[index + 1]]);
    }
  }
  editId(row: any) {
    this.rowObj = row
    this.changeType = 'edit';
    const el: any = this.$refs.addGroup;
    el.isShow = true;
  }
  deleteId(row: any) {
    this.$confirm(
      i18n.t('deleteConfirmMsg', { name: i18n.t('data') }) as string,
      i18n.t('operaConfirmTips') as string,
      {
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        type: 'warning',
      })
      .then(async () => {
        await userGroupDeleteApi(row.id);
        await this.getList()
        this.$message.success(i18n.t('delSucc') as string);
      }).catch(() => {
        this.$message.info(i18n.t('delCancel') as string);
      });
  }
  // 移动API
  async moveCity(data: any) {
    await userGroupMoverOrder(data);
    this.getList();
  }
  handleSizeChange(size: number) {
    this.reqFilterTable.showCount = size;
    this.getList();
  }
  handleCurrentChange(page: number) {
    this.reqFilterTable.currentPage = page;
    this.getList();
  }
  showResource(id: string | number) {
    this.resource.id = id.toString();
    this.resource.visible = true;
  }
  showGroup(id: string | number) {
    this.userSetting.id = id.toString();
    this.userSetting.visible = true;
  }
}
